<template>
	<div class="grid">
		<div class="col-12">
			<div class="card">
				v_industry33 {{perf}}
				<DataTable :value="industries" class="p-datatable-sm" 
					v-model:filters="filters1" responsiveLayout="scroll" showGridlines stripedRows
					:globalFilterFields="['industry_cd', 'industry_name']"
					selectionMode="single">
					<template #header>
						<span class="p-input-icon-left">
							<i class="pi pi-search" />
							<InputText v-model="filters1['global'].value" placeholder="キーワード" />
						</span>
					</template>
					<Column field="id" header="No" :sortable="true"></Column>
					<Column field="industry_cd" header="コード" :sortable="true"></Column>
					<Column field="index_cd" header="index_cd" :sortable="true"></Column>
					<Column field="industry_name" header="業種" :sortable="true"></Column>
          <Column field="meigara_count" header="銘柄数" :sortable="true">
						<template #body="slotProps">
							<div>
								<a href="#" @click.prevent.stop="onShow(slotProps.data)"><span class="">{{slotProps.data.meigara_count}}</span></a>
							</div>
						</template>
					</Column>
					<!-- <Column field="industry_name_en" header="Industry" :sortable="true"></Column>
					<Column field="industry_name_ko" header="업종" :sortable="true"></Column> -->
				</DataTable>
			</div>
			<div>
				<DynamicDialog />
			</div>
		</div>
	</div>
</template>

<script>
import { FilterMatchMode } from 'primevue/api';
import IndustrymcntService from '@/service/IndustrymcntService';
import Company from '@/components/Company';

export default {
	data() {
		return {
			industries: null,
			filters1: {},
			perf: null,
			clickedCode: null
		}
	},
	industrymcntService: null,
	created() {
		this.industrymcntService = new IndustrymcntService();
		this.initFilters1();
	},
	mounted() {
		this.industrymcntService.getAll()
			.then(d => {
				this.industries = d.data;
				this.perf = d.perf;
			}
		);
	},
	methods: {
		initFilters1() {
			this.filters1 = {
				'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
			}
		},
		onShow(params) {
			this.clickedCode = params.industry_cd;
			console.log(this.clickedCode);

			this.$dialog.open(Company, {
				props: {
					header: '上場企業一覧',
					style: {
							// width: '50vw',
							width: '70vw',
					},
					breakpoints:{
							'960px': '75vw',
							'640px': '90vw'
					},
					modal: true,
					dismissableMask: true
				},
				data: {
					industryCode: this.clickedCode ? this.clickedCode : null
				}
			});			
		}
	}
}
</script>

<style scoped>
</style>
