export default class CompanyService {
  getAll(params, industryCode) {
      const queryParams = Object.keys(params).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&');

      let url = null;
      if (industryCode) {
        url = 'https://api.tradersr.com/api/company/' + industryCode + '?'
      } else {
        url = 'https://api.tradersr.com/api/company?'
      }

      return fetch(url + queryParams).then(res => res.json())
  }
}