<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                v_company {{perf}}
                <DataTable :value="companies" class="p-datatable-sm" responsiveLayout="scroll" showGridlines stripedRows
                :lazy="true" :paginator="true" :rows="10" ref="dt" dataKey="id"
                :totalRecords="totalRecords" :loading="loading" @page="onPage($event)" @sort="onSort($event)"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[10,25,50,75,100]"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                selectionMode="single"
                v-model:filters="filters" @filter="onFilter($event)" filterDisplay="row">
                    <Column field="id" header="No" :sortable="true"></Column>
                    <Column field="meigara_cd" header="銘柄コード" filterField="meigara_cd" filterMatchMode="contains" ref="meigara_cd" :sortable="true" :style="{'min-width': '10rem'}">
                      <template #filter="{filterModel,filterCallback}">
                          <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="コード" />
                      </template>
                    </Column>
                    <Column field="meigara_name" header="銘柄名" filterField="meigara_name" filterMatchMode="contains" ref="meigara_name" :sortable="true" :style="{'min-width': '10rem'}">
                      <template #filter="{filterModel,filterCallback}">
                          <InputText type="text" v-model="filterModel.value" @keydown.enter="filterCallback()" class="p-column-filter" placeholder="名称" />
                      </template>
                    </Column>
                    <Column field="market_name" header="市場" :sortable="true">
                    </Column>
                    <Column field="industry33_cd" header="33業種コード" :sortable="true"></Column>
                    <Column field="industry33_name" header="33業種区分" :sortable="true">
                    </Column>
                    <Column field="kibo_cd" header="規模コード" :sortable="true"></Column>
                    <Column field="kibo_name" header="規模区分" :sortable="true"></Column>  
                    <Column field="meigara_name_en" header="銘柄名en" :sortable="true">
                    </Column>
                    <Column field="meigara_url" header="銘柄URL" :sortable="true">
                    </Column>    
                    <Column field="meigara_overview" header="銘柄概要" :sortable="true">
                    </Column>   
                    <Column field="meigara_theme_list" header="銘柄テーマ" :sortable="true">
                    </Column>                                       
                </DataTable>
            </div>
        </div>
    </div>
</template>

<script>
import CompanyService from '@/service/CompanyService';

export default {
    inject: ['dialogRef'],
    data() {
        return {
            loading: false,
            totalRecords: 0,
            companies: null,
            lazyParams: {},
            filters1: {},
            filters: {
                'meigara_cd': {value: '', matchMode: 'contains'},
                'meigara_name': {value: '', matchMode: 'contains'},
            },
            perf: null,
            industryCode: null,
        }
    },
    companyService: null,
    created() {
        this.companyService = new CompanyService();
    },
    mounted() {
        console.log('Company Mounted');
        // console.log(this.dialogRef.data.industryCode);
        if (this.dialogRef) {
            this.industryCode = this.dialogRef.data.industryCode;
        }

        console.log(this.industryCode);

        // this.loading = true;

        this.lazyParams = {
            first: 0,
            rows: this.$refs.dt.rows,
            sortField: null,
            sortOrder: null,
            filters: this.filters
        };

        this.loadLazyData();
    },
    methods: {
        loadLazyData() {
            this.loading = true;
            console.log(this.lazyParams);

            setTimeout(() => {
                this.companyService.getAll(
                    {dt_params: JSON.stringify( this.lazyParams )}, this.industryCode)
                        .then(data => {
                            this.companies = data.payload.data;
                            this.totalRecords = data.payload.total;
                            this.perf = data.perf;

                            this.loading = false;
                    }
                );
            }, Math.random() * 1000 + 250);
        },
        onPage(event) {
            this.lazyParams = event;
            this.loadLazyData();
        },
        onSort(event) {
            this.lazyParams = event;
            this.loadLazyData();
        },
        onFilter() {
            this.lazyParams.filters = this.filters;
            this.loadLazyData();
        }
    }
}
</script>

<style scoped>

</style>